import {Component} from '@angular/core';

@Component({
	selector: 'app-general-error',
	templateUrl: './general-error.component.html',
	styleUrls: ['./general-error.component.css']
})
export class GeneralErrorComponent {

	refreshPage() {
		this.reloadWindow();
	}

	reloadWindow() {
		window.location.reload();
	}

}
