import { Injectable } from '@angular/core';
import {WindowService} from '../_services/window.service';

@Injectable({
	providedIn: 'root'
})
export class FmaService {

	constructor(private readonly windowService: WindowService) {
	}

	getAccessTokenFromWindow() {
		const fma: any = this.windowService.queryWindow('fma');
		try {
			if (fma && fma.CATBundle.access_token) {
				return fma.CATBundle.access_token;
			} else {
				return null;
			}
		} catch (exception) {
			return null;
		}
	}

	getRefreshTokenFromWindow() {
		const fma: any = this.windowService.queryWindow('fma');
		try {
			if (fma && fma.CATBundle.refresh_token) {
				return fma.CATBundle.refresh_token;
			} else {
				return null;
			}
		} catch (exception) {
			return null;
		}
	}

	getUserGuidFromWindow() {
		const fma: any = this.windowService.queryWindow('fma');
		try {
			if (fma && fma.CATBundle.userId) {
				return fma.CATBundle.userId;
			} else {
				return null;
			}
		} catch (exception) {
			return null;
		}
	}

	parseAndGetQueryParameters() {
		const params = {};
		const url = this.windowService.getDeepLinkUrl().split( '?' );
		if (url.length >= 2) {
			const vars = url[1].split('&');
			for (const value of vars) {
				const pair = value.split('=');
				params[pair[0]] = decodeURIComponent(pair[1]);
			}
			return params;
		} else {
			return null;
		}
	}
}
